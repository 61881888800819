import type { ITransitionPage } from '@barba/core';
import timeout from '../utils/timeout';
import gsap from 'gsap';
import pageTrans from '../animations/page-trans';

function contentAnimation() {
    const header = document.querySelector('.a-header');

    const products = document.querySelectorAll('.section-catalog__catalog-item');

    const tl = gsap.timeline({
        onComplete: () => {
            if (typeof onComplete === 'function') {
                onComplete();
            }
            tl.kill();
        },
    });

    tl.from(header, {
        translateY: '-100%',
        duration: 0.6,
        delay: 0.6,
    });

    tl.fromTo(
        products[0],
        {
            opacity: 0,
            translateY: '10px',
        },
        {
            opacity: 1,
            translateY: '0',
            duration: 0.2,
            delay: 0.1,
        },
    );

    tl.fromTo(
        products[1],
        {
            opacity: 0,
            translateY: '10px',
        },
        {
            opacity: 1,
            duration: 0.2,
            translateY: '0',
        },
    );

    tl.fromTo(
        products[2],
        {
            opacity: 0,
            translateY: '10px',
        },
        {
            opacity: 1,
            duration: 0.2,
            translateY: '0',
        },
    );
}

export default {
    name: 'catalog page transition',
    to: {
        namespace: 'catalog-page',
    },

    leave({ current }): Promise<void> {
        return new Promise(async (resolve) => {
            // Определяем аниманию перехода между страниц здесь, в конце асинхронного действия вызываем 'resolve'.
            pageTrans();
            // window.scrollTo({ top: 0, behavior: 'auto' });
            await timeout(600);
            resolve();
        });
    },

    enter(next): Promise<void> {
        return new Promise((resolve) => {
            // window.scrollTo({ top: 0, behavior: 'auto' });
            contentAnimation();
            resolve();
        });
    },

    once(next): Promise<void> {
        return new Promise((resolve) => {
            // window.scrollTo({ top: 0, behavior: 'auto' });
            contentAnimation();
            resolve();
        });
    },
} as ITransitionPage;

/*
function onComplete() {
    throw new Error('Function not implemented.');
}
*/
