import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

export function followToLink() {
    const header = document.querySelector<HTMLElement>('.js-header');
    if (window.location.hash && document.getElementById(window.location.hash.split('#')[1])) {
        gsap.to(window, {
            duration: 2,
            scrollTo: {y:window.location.hash, offsetY: header?.offsetHeight},
            ease: 'power3.inOut',
        });
    }
}