import Swiper, { Pagination, Autoplay } from 'swiper';

const map = new WeakMap<HTMLElement, any>();

function init(container: HTMLElement | Document = document) {
    const inCardSliders = Array.from(container.querySelectorAll<HTMLElement>('.js-in-card-slider'));

    inCardSliders.forEach((sliderEl) => {
        const paginationElement = sliderEl.querySelector<HTMLElement>('.js-in-card-slider-pagination');
        const instance = new Swiper(sliderEl, {
            modules: [Pagination, Autoplay],
            slidesPerView: 1,
            grabCursor: true,
            loop: true,
            pagination: {
                el: paginationElement,
                type: 'bullets',
                clickable: true,
            },
            autoplay: {
                delay: 5000,
            },
        });

        map.set(sliderEl, instance);
    });
}

function destroy(container: HTMLElement | Document = document) {
    const inCardSliders = Array.from(container.querySelectorAll<HTMLElement>('.js-in-card-slider'));

    inCardSliders.forEach((sliderEl) => {
        const instance = map.get(sliderEl);
        instance.destroy();
        map.delete(instance);
    });
}

const _module = { init, destroy };

export default _module;
