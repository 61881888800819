let customSelect : HTMLElement | null;
let selects: HTMLElement[] = [];
let inputText : HTMLElement | null; 

function inputTextOnShow() {
    inputText?.classList.add('is-show')
}

function inputTextOnClose() {
    inputText?.classList.remove('is-show')
}

function init(container: HTMLElement | Document = document) {
    customSelect = container.querySelector<HTMLElement>('.form__item--select');

    if (customSelect) {
        inputText = customSelect.querySelector<HTMLElement>('.text-input__text');
    }

    selects = Array.from(container.querySelectorAll<HTMLElement>('.ss-main'));

    selects.forEach(el => {
         el.addEventListener("focusin", inputTextOnShow );
        el.addEventListener("focusout", inputTextOnClose);
    });

}

function destroy(container: HTMLElement | Document = document) {
    selects.forEach(el => {
        el.removeEventListener("focusin", inputTextOnShow );
       el.removeEventListener("focusout", inputTextOnClose);
   });
}

const _module = { init, destroy };

export default _module;
