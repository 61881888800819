let burgerBtn: HTMLElement | null;
let burgerBtnClose: HTMLElement | null;

function init() {
    burgerBtnClose = document.querySelector<HTMLElement>('.burger-popup__close-btn');
    burgerBtn = document.querySelector<HTMLElement>('.burger');
    burgerBtn?.addEventListener('click', () => {
        burgerBtn?.classList.add('is-show');
    });

    burgerBtnClose?.addEventListener('click', () => {
        burgerBtn?.classList.remove('is-show');
    });
}

function destroy() {
    if (burgerBtn) {
        burgerBtn.classList.remove('is-show');
    }
    burgerBtn = null;
}

const _module = { init, destroy };

export default _module;
