let loaded = 0;
export const sequenceState: { frameCount: number; images: HTMLImageElement[] } = {
    frameCount: 551,
    images: [],
};

const format = document.documentElement.classList.contains('avif') ? 'avif' : 'webp';

const currentFrame = (index: number) =>
    `/img/sequences/${
        matchMedia('(max-width: 1024px) and (orientation: portrait)').matches ? '1080x1920' : '1920x1080'
    }/SECV__${(index + 1).toString().padStart(5, '0')}.${format}`;

for (let i = 0; i < sequenceState.frameCount; i++) {
    const img = new Image();
    img.src = currentFrame(i);
    img.addEventListener(
        'load',
        () => {
            loaded++;

            document.dispatchEvent(
                new CustomEvent('sequence-img-loaded', {
                    detail: { loaded, total: Math.round(sequenceState.frameCount / 2) },
                }),
            );

            if (loaded === Math.round(sequenceState.frameCount / 2)) {
                document.dispatchEvent(new Event('sequence-loaded'));
            }
        },
        { once: true },
    );
    sequenceState.images[i] = img;
}
