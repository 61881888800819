import './webpack-imports';
import './sentry';
import './inits/sequence';
import './custom-elements';
import barba from '@barba/core';
import { IdleQueue } from 'idlize/IdleQueue.mjs';
import { applyPolyfills } from './polyfills';
import { initBaseView } from './views/_base';
import IndexPageView from './views/index';
import Swiper, { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import lazyView from './views/_lazy';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import inputMasks from './inits/masks';
import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import preloader from './components/preloader';
import burger from './inits/burger';
import time from './inits/time';
import headerBluer from './inits/header-blur';
// TRANSITIONS
import BaseTransition from './transitions/base';
import catalogTransition from './transitions/catalog';

document.documentElement.classList.add('js-ready');

applyPolyfills().then(() => {
    const appQueue = new IdleQueue();

    gsap.registerPlugin(CustomEase, ScrollTrigger, ScrollToPlugin);
    Swiper.use([Navigation, Autoplay]);
    window.scrollTo({ top: 0, behavior: 'auto' });

    preloader.init();

    appQueue.pushTask(() => {
        time.init();
        burger.init();
        headerBluer.init();
    });

    document.addEventListener(
        'preloader.complete',
        () => {
            initBaseView();
            calculateScrollbarWidth();

            const requestPopupEl = document.querySelector('.form-popup');
            if (requestPopupEl) {
                inputMasks.init(requestPopupEl);
            }

            barba.init({
                transitions: [BaseTransition, catalogTransition],
                views: [
                    IndexPageView,
                    lazyView('catalog-page', () =>
                        import(/* webpackChunkName: "catalog-page" */ './views/catalog').then((m) => m.default),
                    ),

                    lazyView('contacts-page', () =>
                        import(/* webpackChunkName: "contacts-page" */ './views/contacts').then((m) => m.default),
                    ),

                    lazyView('about-page', () =>
                        import(/* webpackChunkName: "about-page" */ './views/about').then((m) => m.default),
                    ),

                    lazyView('card-detail-page', () =>
                        import(/* webpackChunkName: "card-detail-page" */ './views/card-detail').then((m) => m.default),
                    ),
                ],
                prefetchIgnore: true,
                preventRunning: true,
                timeout: 5000,
                debug: NODE_ENV === 'development',
            });
        },
        { once: true },
    );
});
