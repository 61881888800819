import barba from '@barba/core';
import { IdleQueue } from 'idlize/IdleQueue.mjs';
import { lazyload } from '../inits/lazy-load';
import ajaxForm from '../inits/ajax-form';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import select from '../inits/select';
import selectTitle from '../inits/select-title-focus';
import reveal from '../animations/reveal';
import { followToLink } from '../inits/followLink';

const beforeEnterQueue = new IdleQueue();

export function initBaseView() {
    history.scrollRestoration = 'manual';
    const scrollPosY = [0];

    barba.hooks.beforeEnter((data) => {
        beforeEnterQueue.pushTask(() => {
            ajaxForm.init();
        });

        beforeEnterQueue.pushTask(() => {
            select.init();
        });

        beforeEnterQueue.pushTask(() => {
            selectTitle.init();
        });

        beforeEnterQueue.pushTask(() => {
            reveal.init();
        });
    });

    barba.hooks.afterEnter((data) => {
        if (data) {
            if (data.trigger !== 'back') {
                window.scrollTo(0, 0);
            } else {
                setTimeout(() => {
                    window.scrollTo(0, scrollPosY.pop());
                }, 1);
            }
        }
        setTimeout(() => {
            followToLink();
        }, 800);
    });

    barba.hooks.beforeLeave((data) => {
        beforeEnterQueue.clearPendingTasks();
        ajaxForm.destroy();
        selectTitle.destroy();
    });

    barba.hooks.enter((data) => {
        const popups = Array.from(document.querySelectorAll<LitPopupElement>('app-lit-popup'));
        if (popups.length) {
            popups.forEach((popup) => {
                if (popup.instance.isOpen) {
                    popup.close();
                }
                popup.classList.remove('lit-popup--opened');
                popup.classList.remove('is-show');
            });
        }

        if (data && data.current && data.next && data.current.namespace && data.next.namespace) {
            document.body.classList.replace(data.current.namespace, data.next.namespace);
        }
        if (data && data.trigger !== 'back') {
            scrollPosY.push(barba.history.current.scroll.y);
        }
        lazyload.update();
    });

    barba.hooks.afterLeave((data) => {
        document.body.classList.remove('no-scroll');
    });
}
