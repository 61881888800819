import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import debounce from 'lodash.debounce';
import { sequenceState } from './sequence';

let sequenceCard: HTMLElement[] = [];
let canvas: HTMLCanvasElement | null;
let context: CanvasRenderingContext2D | null;
const seq = { frame: 0 };
let prevFrame = -1;

function render(force = false) {
    if (canvas && context && sequenceState.images[seq.frame] && (force ? true : seq.frame !== prevFrame)) {
        const isMobile = matchMedia('(max-width: 1024px) and (orientation: portrait)').matches;

        const imgWidth = isMobile ? 1080 : 1920;
        const imgHeight = isMobile ? 1920 : 1080;
        const imgAspectRatio = imgWidth / imgHeight;
        const canvasAspectRatio = canvas.width / canvas.height;
        let x = 0;
        let y = 0;
        let width = canvas.width;
        let height = canvas.height;

        if (imgAspectRatio < canvasAspectRatio) {
            height = width / imgAspectRatio;
            y = (canvas.height - height) / 2;
        } else {
            width = height * imgAspectRatio;
            x = (canvas.width - width) / 2;
        }

        context.drawImage(sequenceState.images[seq.frame], x, y, width, height);

        if (sequenceState.images[seq.frame].complete) {
            prevFrame = seq.frame;
        }
    }
}

function setCanvasDimensions() {
    const dpr = Math.min(window.devicePixelRatio, 2);
    if (canvas) {
        canvas.width = window.innerWidth * dpr;
        canvas.height = window.innerHeight * dpr;
        render(true);
    }
}

function onResize() {
    setCanvasDimensions();
}

const debouncedOnResize = debounce(onResize, 100);

let sequenceCardTl: gsap.core.Timeline | null;
let sequenceCardPinTl: gsap.core.Timeline | null;

function init(container: HTMLElement | Document = document) {
    canvas = container.querySelector<HTMLCanvasElement>('canvas#v0');
    sequenceCard = Array.from(container.querySelectorAll<HTMLVideoElement>('.section-sequence__card'));

    if (canvas) {
        context = canvas.getContext('2d');
        setCanvasDimensions();

        gsap.to(seq, {
            frame: sequenceState.frameCount - 1,
            snap: 'frame',
            ease: 'none',
            scrollTrigger: {
                trigger: '#set-height',
                start: 'top top',
                end: 'bottom bottom',
                scrub: true,
            },
            onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
        });

        if (sequenceState.images[0]) {
            if (sequenceState.images[0].complete) {
                render();
            } else {
                sequenceState.images[0].onload = () => render();
            }
        }
    }

    sequenceCard.forEach((el) => {
        ScrollTrigger.matchMedia({
            // desktop
            '(min-width: 1024px)': function () {
                sequenceCardTl = gsap.timeline({
                    scrollTrigger: {
                        trigger: el,
                        start: `center bottom`,
                        end: `+=${window.screen.availHeight * 1.5}`,
                        onEnterBack: ({ progress, direction, isActive }) => el.classList.add('is-show'),
                        onEnter: ({ progress, direction, isActive }) => el.classList.add('is-show'),
                        onLeave: ({ progress, direction, isActive }) => el.classList.remove('is-show'),
                        onLeaveBack: ({ progress, direction, isActive }) => el.classList.remove('is-show'),
                    },
                });

                sequenceCardPinTl = gsap.timeline({
                    scrollTrigger: {
                        trigger: el,
                        start: `center center`,
                        end: `+=700}`,
                        pin: true,
                    },
                });
            },

            '(max-width: 1023px)': function () {
                sequenceCardTl = gsap.timeline({
                    scrollTrigger: {
                        trigger: el,
                        start: `bottom bottom`,
                        end: `+=${window.screen.availHeight - 200}`,
                        onEnterBack: ({ progress, direction, isActive }) => el.classList.add('is-show'),
                        onEnter: ({ progress, direction, isActive }) => el.classList.add('is-show'),
                        onLeave: ({ progress, direction, isActive }) => el.classList.remove('is-show'),
                        onLeaveBack: ({ progress, direction, isActive }) => el.classList.remove('is-show'),
                    },
                });

                sequenceCardPinTl = gsap.timeline({
                    scrollTrigger: {
                        trigger: el,
                        start: `center center`,
                        end: `+=400}`,
                        pin: true,
                    },
                });
            },
        });
    });

    window.addEventListener('resize', debouncedOnResize);
}

function destroy(container: HTMLElement | Document = document) {
    window.removeEventListener('resize', debouncedOnResize);

    if (!!sequenceCardTl) {
        sequenceCardTl.kill();
        sequenceCardTl = null;
    }

    if (!!sequenceCardPinTl) {
        sequenceCardPinTl.kill();
        sequenceCardPinTl = null;
    }
}

const _module = { init, destroy };

export default _module;
