import { LitElement, html } from 'lit';
import { withLeadingZero } from '../../utils/strings';

export interface Clock {
    locale: string;
    time: string;
    timezone: string;
    _date: Date;
    _timer: NodeJS.Timeout;
}

function toLocaleTimeStringSupportsLocales() {
    try {
        new Date().toLocaleTimeString('i');
    } catch (e) {
        return e.name === 'RangeError';
    }
    return false;
}

export class Clock extends LitElement {
    static get properties() {
        return {
            locale: {
                type: String,
                value: 'ru-RU',
            },
            time: {
                type: String,
            },
            timezone: {
                type: String,
            },
        };
    }

    connectedCallback() {
        super.connectedCallback();
        this.updateTime = this.updateTime.bind(this);

        this.updateTime();
        this._timer = setInterval(this.updateTime, 1000);
    }

    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
        super.attributeChangedCallback(name, oldValue, newValue);

        if (name === 'timezone') {
            if (oldValue && newValue) {
                clearInterval(this._timer);
                this.updateTime();
                this._timer = setInterval(this.updateTime, 1000);
            }
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        clearTimeout(this._timer);
    }

    getTime() {
        const [hours, minutes] = this.time.split(':');
        return { raw: this.time, hours: parseInt(hours, 10), minutes: parseInt(minutes, 10) };
    }

    updateTime() {
        const date = new Date();

        if (toLocaleTimeStringSupportsLocales()) {
            this.time = date.toLocaleTimeString(this.locale, {
                timeZone: this.timezone,
                hour: '2-digit',
                minute: '2-digit',
            });
        } else {
            this.time = `${withLeadingZero(date.getHours())}:${withLeadingZero(date.getMinutes())}`;
        }
    }

    render() {
        return html`${this.time}`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-clock': Clock;
    }
}

customElements.define('app-clock', Clock);
