import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function init() {
    const header = document.querySelector<HTMLElement>('.js-header');
    const footer = document.querySelector<HTMLElement>('.footer');
   
    ScrollTrigger.create({
        trigger: header,
        start: `${header?.offsetHeight}`,
        endTrigger: footer,
        end: "bottom bottom",
        onEnter: ()=> {
            header?.classList.add('blur');
       },
         onLeave: ()=> {
            header?.classList.remove('blur');
        },

        onEnterBack: ()=> {
            header?.classList.add('blur');
        },

        onLeaveBack: ()=> {
            header?.classList.remove('blur');
        }
      });

}

const _module = { init };

export default _module;
