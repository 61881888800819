import Swiper, { Navigation, Lazy } from 'swiper';
import { CSSSelector } from 'swiper/types/shared';
import LitPopup from 'lit-popup';

let sliders: HTMLElement[] = [];
const map = new WeakMap();

const sliderOptions = {
    modules: [Lazy],
    slidesPerView: 1,
    speed: 1300,
    spaceBetween: 8,
    loop: true,
    centeredSlides: true,
    preloadImages: false,
    // centerInsufficientSlides: true,
    lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 5,
        checkInView: true,
    },
    navigation: {
        nextEl: '.btn-right',
        prevEl: '.btn-left',
    },
    breakpoints: {
        1280: {
            slidesPerView: 3,
            lazy: {
                loadPrevNextAmount: 2,
            },
        },

        1024: {
            slidesPerView: 2,
            lazy: {
                loadPrevNextAmount: 1,
            },
        },
        320: {
            slidesPerView: 1,
            lazy: {
                loadPrevNextAmount: 1,
            },
        },
    },
};

function sliderInit() {
    if (sliders) {
        sliders.forEach((sliderEl) => {
            const catalogSlider = new Swiper(sliderEl, sliderOptions);
            map.set(sliders, catalogSlider);
        });
    }
}

function init(container: HTMLElement | Document = document) {
    sliders = Array.from(container.querySelectorAll<HTMLElement>('.js-catalog-swiper'));
    sliderInit();
}

function destroy(container: HTMLElement | Document = document) {
    if (sliders) {
        sliders.forEach((sliderEl) => {
            const sliders = map.get(sliderEl);
            if (sliders) {
                sliders.destroy();
                map.delete(sliderEl);
            }
        });
    }
    sliders = [];
}

const _module = { init, destroy };

export default _module;
