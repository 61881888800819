import type { ITransitionPage } from '@barba/core';
import timeout from '../utils/timeout';
import gsap from 'gsap';
import pageTrans from '../animations/page-trans';

function headerTrans() {
    const header = document.querySelector('.a-header');

    const tl = gsap.timeline({
        onComplete: () => {
            if (typeof onComplete === 'function') {
                onComplete();
            }
            tl.kill();
        },
    });

    tl.from(header, {
        translateY: '-100%',
        duration: 0.6,
        delay: 0.2,
    });
}

export default {
    leave({ current }): Promise<void> {
        return new Promise(async (resolve) => {
            // Определяем аниманию перехода между страниц здесь, в конце асинхронного действия вызываем 'resolve'.
            pageTrans();
            // window.scrollTo({ top: 0, behavior: 'auto' });
            await timeout(600);
            resolve();
        });
    },

    enter(next): Promise<void> {
        return new Promise((resolve) => {
            // window.scrollTo({ top: 0, behavior: 'auto' });
            headerTrans();
            resolve();
        });
    },

    once(next): Promise<void> {
        return new Promise((resolve) => {
            // window.scrollTo({ top: 0, behavior: 'auto' });
            headerTrans();
            resolve();
        });
    },
} as ITransitionPage;

/*
function onComplete() {
    throw new Error('Function not implemented.');
}
*/
