import axios from 'axios';
import gsap from 'gsap';
import timeout from '../utils/timeout';
import { triggerCustomEvent } from '../utils/trigger-event';

const NO_SCROLL_CLASS = 'no-scroll';

function init() {
    function createPreloader() {
        const preloader = document.querySelector('.js-preloader');
        const indicator = document.querySelector('.js-preloader-text');
        const svg = document.querySelector('.js-preloader-svg');
        const path = document.querySelector<SVGPathElement>('path.js-preloader-path');
        const pathLength = path?.getTotalLength();
        let assets: number[] = [];
        let wasBodyLocked = false;
        document.body.classList.add(NO_SCROLL_CLASS);
        const isSequencePage = !!document.querySelector('canvas#v0');

        const onResize = () => {
            gsap.set(svg, {
                scale: window.matchMedia('(orientation: portrait)').matches ? 1920 / 1080 : 1,
            });
        };

        onResize();
        window.addEventListener('resize', onResize);

        async function enter() {
            if (preloader) {
                wasBodyLocked = document.body.classList.contains('no-scroll');
                if (!wasBodyLocked) {
                    document.body.classList.remove(NO_SCROLL_CLASS);
                }
            }
        }

        function leave() {
            if (preloader) {
                preloader.classList.add('preloader--complete');
                setTimeout(() => {
                    preloader.classList.add('preloader--hidden');
                }, 1500);
                if (!wasBodyLocked) {
                    document.body.classList.remove(NO_SCROLL_CLASS);
                }
            }

            window.removeEventListener('resize', onResize);
        }

        const animatePath = (percent: number) => {
            if (path && typeof pathLength === 'number') {
                const FINAL_PATH_STROKE_DASHOFFSET_VALUE = 27510; // магическое число, такая уж свг, сорри

                if ('requestIdleCallback' in window) {
                    requestIdleCallback(() => {
                        path.style.setProperty(
                            '--path-dashoffset',
                            `${pathLength - (pathLength - FINAL_PATH_STROKE_DASHOFFSET_VALUE) * (percent * 0.01)}px`,
                        );
                    });
                } else {
                    requestAnimationFrame(() => {
                        path.style.setProperty(
                            '--path-dashoffset',
                            `${pathLength - (pathLength - FINAL_PATH_STROKE_DASHOFFSET_VALUE) * (percent * 0.01)}px`,
                        );
                    });
                }
            }
        };

        animatePath(25);

        function setPercentIndicatorValue(value: number) {
            if (indicator) {
                if (!isNaN(value)) {
                    const finalValue = value === Infinity ? 100 : value;

                    if (finalValue > 25 && finalValue <= 50) {
                        animatePath(50);
                    } else if (finalValue > 50 && finalValue <= 75) {
                        animatePath(75);
                    } else if (finalValue >= 85) {
                        animatePath(100);
                    }

                    indicator.textContent = `${finalValue}%`;
                }
            }

            if (value === 100) {
                assets = [];
                triggerCustomEvent(document, 'preloader.complete');
            } else if (value == Infinity) {
                assets = [];
                triggerCustomEvent(document, 'preloader.complete');
            }
        }

        async function loadAsset(url: string, index: number, amount: number) {
            try {
                await axios.get(url, {
                    onDownloadProgress: (progressEvent) => {
                        let percent = 0;
                        const _percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        assets[index] = _percentCompleted;

                        for (let i = 0; i < assets.length; i++) {
                            percent += assets[i];
                        }

                        setPercentIndicatorValue(Math.round(percent / amount));
                    },
                });
            } catch (err) {
                let percent = 0;
                assets[index] = 100;

                for (let i = 0; i < assets.length; i++) {
                    percent += assets[i];
                }

                setPercentIndicatorValue(Math.round(percent / amount));
            }
        }

        async function loadAssetsFromElement(element: Element | Document = document) {
            const images = Array.from(
                element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not(.swiper-lazy):not([loading="lazy"])'),
            );

            const waitForSequenceFramesToLoad = () =>
                new Promise((resolve) => {
                    document.addEventListener('sequence-img-loaded', function onSequenceImgLoaded(event) {
                        const percent = Math.round((event.detail.loaded / event.detail.total) * 100);

                        if (percent >= 100) {
                            document.removeEventListener('sequence-img-loaded', onSequenceImgLoaded);
                        }

                        setPercentIndicatorValue(percent);
                    });
                    document.addEventListener('sequence-loaded', resolve, { once: true });
                });

            triggerCustomEvent(document, 'preloader.begin');

            if (isSequencePage) {
                await waitForSequenceFramesToLoad();
            } else {
                await Promise.all([...images.map((img, i) => loadAsset(img.currentSrc, i, images.length))]);
            }
        }

        return { enter, leave, loadAssetsFromElement } as const;
    }

    let preloader = createPreloader();

    // Initial load
    preloader
        .loadAssetsFromElement()
        .then(() => timeout(500 + 2000))
        .then(async () => {
            requestAnimationFrame(() => {
                preloader.leave();
            });
        });
}

export default { init };
