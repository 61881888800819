import { Clock } from '../custom-elements/Clock/Clock';
import { withLeadingZero } from '../utils/strings';

const KRSK_TIMEZONE = 'Asia/Krasnoyarsk';

function toLocaleTimeStringSupportsLocales() {
    try {
        new Date().toLocaleTimeString('i');
    } catch (e: any) {
        return e.name === 'RangeError';
    }
    return false;
}

function getTime(time: string) {
    const [hours, minutes] = time.split(':');
    return { raw: time, hours: parseInt(hours, 10), minutes: parseInt(minutes, 10) };
}

function init() {
    const date = new Date();
    const timerBlocks = Array.from(document.querySelectorAll<Clock>('.js-time'));
    // const timezone = 'Intl' in window ? Intl.DateTimeFormat().resolvedOptions().timeZone : KRSK_TIMEZONE;

    const krskTime = toLocaleTimeStringSupportsLocales()
        ? date.toLocaleTimeString('ru-RU', {
              timeZone: KRSK_TIMEZONE,
              hour: '2-digit',
              minute: '2-digit',
          })
        : `${withLeadingZero(date.getHours())}:${withLeadingZero(date.getMinutes())}`;
    const krskTimeValue = getTime(krskTime);

    timerBlocks.forEach((el) => {
        // el.timezone = timezone;
        const timeStatus = el.parentElement?.querySelector('.js-time-status');

        if (timeStatus) {
            if (krskTimeValue.hours >= 9 && krskTimeValue.hours < 18) {
                // daytime
                timeStatus.classList.remove('header__time-status-text--disabled');
            } else {
                // nighttime
                timeStatus.classList.add('header__time-status-text--disabled');
            }
        }
    });
}

function destroy() {
    //
}

const _module = { init, destroy };

export default _module;
